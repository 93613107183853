import Scrollspy from 'react-scrollspy'
import React from "react"

export default (props) => (
	<nav className={props.stickyNav ? 'stickyNav nav' : 'nav'}>
	  <Scrollspy  
	  items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5'] } 
	  currentClassName="is-current" offset={0}>
	    <li ><a href="#section-1">Downloadable Resources</a></li>
	    <li><a href="#section-2">VASCEPA Savings Card</a></li>
	    <li><a href="#section-3">Peer-to-Peer Educational Video</a></li>
	    <li><a href="#section-4">Virtual Speaker Programs</a></li>
	    <li><a href="#section-5">On-Demand Expert Perspectives</a></li>
	  </Scrollspy>
	</nav>
)
