import React from "react"
import {
  patientJames,
  patientDonna,
  patientDavid,
  patientJames_mob,
  patientDonna_mob,
  patientDavid_mob,
} from "../../images/imgExporter"

import Collapsible from "../collapsible/Collapsible"
import { Link } from "gatsby"

import VideoHeaderMyth from "../Video/VideoHeaderMyth"
import Video from "../Video/Video"

import EfficacyData from "../../images/resources/efficacy-data-new.png"
import Blink from "../../images/resources/blinkrx-e-prescribe.png"
import Canto from "../../images/resources/canto.png"
import DawCode from "../../images/resources/daw-code.png"
import FlashCard from "../../images/resources/flashcard.png"
import BlinkRxTroubleShootingGuide from "../../images/resources/BlinkRxTroubleShootingGuide.png"
import Handout from "../../images/resources/handout.png"
import Karalis from "../../images/resources/karalis.png"
import Kohli from "../../images/resources/kohli.png"
import Omega3 from "../../images/resources/omega3.png"
import SecondaryPreventionData from "../../images/resources/secondary-prevention-data.png"
import SelectPlan from "../../images/resources/select-plan.png"
import StateDaw from "../../images/resources/state-daw.png"
import CardFPO from "../../images/graphs/desktop/savingscard.jpg"
import BlinkRxTroubleShootingGuidePDF from "../../pdfs/US-VAS-03469_HCP_Blink_Rx_EMR_Troubleshooting_Guide_Oct_21.pdf"

/* styles */
import "./styles.css"

export default props => {
  const collapsiblewidth = props.isMobile ? "100%" : "505px"

  return (
    <main className="main">
      <section id="section-1">
        <h4 className="section-header m-t-60">Downloadable Resources</h4>
        <div className="resourceGrid-container">
          <div className="resourceGrid ">
            <h3>VASCEPA Efficacy Data</h3>
            <img src={EfficacyData} alt="VASCEPA Efficacy Data" />
            <p>Key data and safety information for VASCEPA </p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/US-VAS-03640_HCP_Leave_Behind_with_PI.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>State DAW Requirements</h3>
            <img src={StateDaw} alt="State DAW Requirements" />
            <p>
              Reference sheet for any state-specific requirements to dispense
              branded VASCEPA
            </p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/State_DAW_Requirements.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>ICD-10 Coding Flashcard</h3>
            <img src={FlashCard} alt="ICD-10 Coding Flashcard" />
            <p>Information to help complete payer forms for VASCEPA</p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/Coding_Flashcard.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          
          
          
          <div className="resourceGrid ">
            <h3>Formulary Status for Select Plans</h3>
            <img src={SelectPlan} alt="Formulary Status for Select Plans" />
            <p>Information on which coverage plans exclusively carry VASCEPA</p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/Formulary_Status.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          
          <div className="resourceGrid dropdownGrid">
            <h3>E-prescribe VASCEPA to BlinkRx U.S.</h3>
            <img src={Blink} alt="E-prescribe VASCEPA to BlinkRx U.S." />
            <div className="with-dropdown">
              <p>
                Let BlinkRx help with prior authorization support and free home
                delivery for your VASCEPA patients
              </p>
              <br />
              <p className="m-b-10 bold">Download</p>
              <div class="select-box">
                <div class="chevron"></div>
                <label>
                  <b>Select language</b>
                </label>
                <ul>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03468BlinkRxProviderFlyer_English.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      English
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03520BlinkRxProviderFlyer_SimplifiedChinese.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      Simplified Chinese
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03519BlinkRxProviderFlyer_Spanish.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      Spanish
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03521BlinkRxProviderFlyer_TraditionalChinese.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      Traditional Chinese
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="resourceGrid ">
            <h3>BlinkRx Troubleshooting Guide</h3>
            <img
              src={BlinkRxTroubleShootingGuide}
              alt="BlinkRx Troubleshooting Guide"
            />
            <div className="with-dropdown">
              <p>
                If you're having trouble finding BlinkRx in the EMR, this guide
                will take you step by step through the process
              </p>
              <br />
              <p className="m-b-10 bold">Download</p>
              <div class="select-box">
                <div class="chevron"></div>
                <label>
                  <b>Select language</b>
                </label>
                <ul>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03469BlinkRxTroubleshootingGuide_English.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      English
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03517BlinkRxTroubleshootingGuide_SimplifiedChinese.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      Simplified Chinese
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03516BlinkRxTroubleshootingGuide_Spanish.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      Spanish
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://amarincorp.com/docs/US-VAS-03518BlinkRxTroubleshootingGuide_TraditionalChinese.pdf"
                      target="_blank"
                      className="select-option"
                    >
                      Traditional Chinese
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />

      <section id="section-2">
        <h4 className="section-header">VASCEPA Savings Card</h4>
        <div className="resource-savings-card">
          <img src={CardFPO} alt="VASCEPA Savings Card" />
          <p style={{ "margin-top": "0px" }}>
            Learn how eligible patients can sign up to save on VASCEPA with the
            universal VASCEPA Savings Card.*
            <br />
            <br />
            For eligible patients only. Maximum savings and other terms and conditions apply. See{" "}
            <a href="https://vascepa.copaysavingsprogram.com" target="_blank">
              <strong>https://vascepa.copaysavingsprogram.com</strong>
            </a>{" "}
            for details.
            <br />
            <br />
            Twice as many patients can pay $9 or less for branded VASCEPA compared to generic icosapent ethyl.<sup>&dagger;</sup>

          </p>
          <div>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/VASCEPA_Savings_Card.pdf"
              className="cta-btn"
            >
              Download card
            </a>
          </div>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />

      <section id="section-3" class="resource-video-section">
        <h4 className="section-header m-b-35">
          Peer-to-Peer Educational Video
        </h4>
        <div id="LDL_lowering_video" className="mf-video-section">
          <Video
            video_id="486972087"
            video_id_2="489545925"
            video_id_3="257211618"
            headline="Watch Dr. Kohli debunk a common misconception regarding <span class='nowrap'>LDL-C</span> and Persistent Cardiovascular Risk <span class='nowrap'>(P-CVR)</span>"
          />
        </div>
      </section>
      <hr className="m-y-40 mobile-m-y-25" />
      <section id="section-4" className="speaker-program-section">
        <h4 className="section-header">Virtual Speaker Programs</h4>
        <div className="p-x-15">
          <p>
            <strong>
              Join your peers at convenient times as they discuss how your
              statin-treated patients can benefit from VASCEPA
            </strong>
          </p>
          <a
            target="_blank"
            href="https://events.medpt.com/vascepa/webcast"
            className="cta-btn"
          >
            Register now
          </a>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />

      <section id="section-5">
        <h4 className="section-header">On-Demand Expert Perspectives</h4>
        <div className="resourceGrid-container">
          <div className="resourceGrid ">
            <h3>Expert Perspective: Dr. Canto</h3>
            <img src={Canto} alt="Dr. Canto headshot" />
            <p>
              Watch Dr. John Canto highlight the urgency to treat{" "}
              <span class="nowrap">P-CVR</span> and the role of VASCEPA
            </p>
            <a
              target="_blank"
              href="https://onlinexperiences.com/scripts/Server.nxp?LASCmd=AI:4;F:QS!10100&ShowUUID=63B2387E-E9B7-44CC-BC91-A33F78F02F46"
              className="cta-btn"
            >
              Register and watch
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>Expert Perspective: Dr. Kohli</h3>
            <img src={Kohli} alt="Dr. Kohli headshot" />
            <p>
              Watch Dr. Payal Kohli speak at AHA 2020 about the benefits of
              VASCEPA in elevating the standard of cardiovascular care
            </p>
            <a
              target="_blank"
              href="https://bit.ly/vascepa_DrKohli"
              className="cta-btn"
            >
              Register and watch
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>Expert Perspective: Dr. Karalis</h3>
            <img src={Karalis} alt="Dr. Karalis headshot" />
            <p>
              Watch Dr. Dean Karalis speak at NLA 2020 about reducing CV risk
              with VASCEPA
            </p>
            <a
              target="_blank"
              href="https://bit.ly/vascepa_DrKaralis"
              className="cta-btn"
            >
              Register and watch
            </a>
          </div>
        </div>
      </section>
    </main>
  )
}
