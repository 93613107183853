import React from "react"

export default (props) => (
	<div className={`hero-container ${props.className}`}>
		<div className={`hero-content-container heroimage ${props.bckgrdImage}`}>
			<div className="hero-grid no-img" style={{paddingBottom: "5px"}}>
				<section className="hero-content">
					<h1 className="headline m-y-5">Helpful resources to support your practice</h1>	
				</section>
			</div>
		</div>
	</div>
)
